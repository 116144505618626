import { createStore } from "vuex";

const tokenInfo = localStorage.getItem("_tokenInfo");
const userInfo = localStorage.getItem("_userInfo");

export default createStore({
  state: {
    tokenInfo: JSON.parse(tokenInfo),
    isLogin: tokenInfo ? true : false,
    userInfo: JSON.parse(userInfo) || {},
    name: tokenInfo ? JSON.parse(userInfo).name : null,
    username: tokenInfo ? JSON.parse(userInfo).username : null,
    avatarUrl: tokenInfo ? JSON.parse(userInfo).avatarUrl : null,
    // super_user: tokenInfo ? JSON.parse(JSON.parse(tokenInfo).super_user) : null,
    roles: userInfo ? JSON.parse(userInfo).roles : [],
  },
  mutations: {
    SET_LOGIN_STATUS(state, val) {
      state.isLogin = val;
    },
    SET_USERNAME(state, val) {
      state.username = val;
    },
    // SET_ISADMIN(state, val) {
    //   state.isAdmin = val;
    // },
    SET_TOKENINFO(state, val) {
      state.tokenInfo = val;
    },
    SET_USERINFO(state, val) {
      state.userInfo = val;
      localStorage.setItem("_userInfo", JSON.stringify(val));
    },
    // SET_SUPERUSER(state, val) {
    //   state.super_user = val;
    // },
    SET_OPENCOUNT(state, val) {
      state.opencount = val;
    },
    SET_ROLES(state, val) {
      state.roles = val;
    },
    SET_LOGOUT(state) {
      state.tokenInfo = null;
      state.userInfo = null;
    },
  },
  actions: {},
  modules: {},
});
