
import * as dayjs from "dayjs";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export default {
  //时间格式化
  formatTime(dateStr, format) {
    if (format) return dayjs(dateStr).format(format);
    return dayjs(dateStr).format("YYYY/MM/DD HH:mm:ss");
  },
  // 相对时间
  DateFormNowNt(dateStr) {
    return dayjs(dateStr).fromNow();
  },
  getMonth() {
    const str = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    return str[dayjs().get("month")];
  },
};
