const _console = console;
const createlog = (util) => (...args) => {
  const fun = _console[util] ? _console[util] : _console.log;
  fun.apply(void 0, args);
};

const hello = (title, version) =>
  createlog("log")(
    `%c ${title} %c ${version} `,
    "padding: 2px 1px; border-radius: 3px 0 0 3px; color: #fff; background: #606060; font-weight: bold;",
    "padding: 2px 1px; border-radius: 0 3px 3px 0; color: #fff; background: #42c02e; font-weight: bold;"
  );

hello('workstats-front', '0.9.4', '7b641f9c6604d6890779c43bead027fdeb086d0c');
