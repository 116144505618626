import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import store from "../store";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  if (to.name != "Login") {
    if (!store.state.isLogin) {
      return "/login";
    }
  }

  if (to.path.indexOf("admin") >= 0) {
    if (!store.state.roles) {
      return "/login";
    }
    if (
      !store.state.roles.some((item) => item == "MANAGER" || item == "ADMIN")
    ) {
      return "/";
    }
  }

});

export default router;
