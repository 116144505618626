<template>
  <a-layout class="container">
    <a-layout-header class="header">
      <div class="d-flex">
        <h1 class="logo">WorkStats</h1>
        <!-- <a-menu
          theme="dark"
          mode="horizontal"
          v-model:selectedKeys="selectedKeys1"
        >
          <a-menu-item key="1" @click="$router.push('/')">
            Personal</a-menu-item
          >
          <a-menu-item key="2" @click="$router.push('/admin')" v-if="isAdmin"
            >Admin</a-menu-item
          >
        </a-menu> -->
      </div>

      <a-dropdown v-if="store.state.isLogin">
        <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
          <!-- <a-avatar :src="avatarUrl" /> -->
          {{ name }} <DownOutlined />
        </a>
        <template #overlay>
          <a-menu>
            <a-menu-item>
              <a href="javascript:;" @click="doLogout">Logout</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <a-button v-else @click="doLogin">Login</a-button>
    </a-layout-header>

    <a-layout class="child-container">
      <a-layout-sider
        class="a-layout-sider"
        width="200"
        breakpoint="lg"
        collapsed-width="0"
      >
        <!-- <SiderbarAdmin /> -->
        <Siderbar />
      </a-layout-sider>

      <a-layout style="padding: 0 24px; position: relative">
        <a-breadcrumb style="margin: 16px 0" />

        <router-view v-slot="{ Component }">
          <transition name="slide-left" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
        <a-layout-footer class="footer"
          >&copy;2022 Springup Inc.</a-layout-footer
        >
      </a-layout>
    </a-layout>
  </a-layout>
</template>

<script>
import { DownOutlined } from "@ant-design/icons-vue";
import { reactive, toRefs, watch, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Siderbar from "./Siderbar.vue";
// import SiderbarAdmin from "./SiderbarAdmin.vue";
const menuList = [
  // {
  //     name: "Home",
  //     link: "/",
  //     icon: "TeamOutlined"
  // },
  {
    name: "Overview",
    link: "/overview",
    icon: "TeamOutlined",
  },
  {
    name: "Off Days",
    link: "/projects",
    icon: "TeamOutlined",
  },
  {
    name: "Salary",
    link: "/teams",
    icon: "TeamOutlined",
  },
  {
    name: "Admin",
    link: "/users",
    icon: "TeamOutlined",
  },
  {
    name: "Repositories",
    link: "/Repositories",
    icon: "TeamOutlined",
  },
];

export default {
  components: {
    DownOutlined,
    Siderbar,
    // SiderbarAdmin,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      userInfo: {},
      selectedKeys: [0],
      openKeys: [0],
      menuList,
      selectedKeys1: ["1"],
    });

    localStorage.getItem("_tokenInfo") &&
      store.commit("SET_LOGIN_STATUS", true);

    if (route.path.indexOf("admin") >= 0) {
      state.selectedKeys1 = ["2"];
    }
    // 根据当前页面路径选中对应Menu
    Object.keys(menuList).map((key) => {
      if (menuList[key].link == router.currentRoute.value.path) {
        state.selectedKeys = [+key];
        return;
      }
    });

    // async function getUserInfo() {
    //   await setTimeout(() => {
    //     state.userInfo = store.state.userInfo;
    //     // state.userInfo = JSON.parse(window.localStorage.getItem("_userInfo"));
    //   }, 100);
    // }
    // getUserInfo();
    // 页面切换
    const navigate = ({ key }) => {
      router.replace(state.menuList[key].link);
    };
    // 登录
    const doLogin = () => {
      router.push("/login");
    };
    // 注销
    const doLogout = (e) => {
      e.preventDefault();
      localStorage.clear();
      store.commit("SET_LOGIN_STATUS", false);
      store.commit("SET_LOGOUT");
      router.replace("/login");
    };

    const selectedKeys = ref(["1"]);
    const openKeys = ref(["sub1"]);

    const handleClick = (e) => {
      console.log("click", e);
    };

    const titleClick = ({ key }) => {
      router.replace(state.menuList[key].link);
    };

    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal;
      }
    );

    const name = computed(() => store.state.userInfo.name);
    const avatarUrl = computed(() => store.state.avatarUrl);
    const isAdmin = computed(() =>
      store.state.roles.some((item) => item == "MANAGER" || item == "ADMIN")
    );

    if (isAdmin.value) {
      state.selectedKeys = ["2"];
    }

    return {
      ...toRefs(state),
      store,
      navigate,
      doLogin,
      doLogout,
      selectedKeys,
      openKeys,
      handleClick,
      titleClick,

      name,
      avatarUrl,
      isAdmin,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/style/mixins";
@import "./layout.scss";

.ant-layout {
  height: 100%;
  overflow: auto;
}
.logo {
  width: 150px;
  // height: 31px;
  margin: 16px 24px 16px 0;
}
</style>
