import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
  ApolloLink,
  split,
  gql,fromPromise 
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";

import router from "../router";
import { onError } from "@apollo/client/link/error";
import { services } from "../../config";
import { provideApolloClient, useMutation } from "@vue/apollo-composable";

const httpLink = createHttpLink({
  uri: `${services.server}/graphql`,
  // uri: `https://gitlab.springup.xyz/api/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const tokenInfo = JSON.parse(localStorage.getItem("_tokenInfo"));

  //return the headers to the context so httpLink can read them
  if (tokenInfo && tokenInfo.token) {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${tokenInfo.token}`,
      },
    };
  } else {
    return headers;
  }
});

let isRefreshing = false;
let pendingRequests = [];

const resolvePendingRequests = () => {
  pendingRequests.map((callback) => callback());
  pendingRequests = [];
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let error of graphQLErrors) {
        if (
          error.message.includes("Invalid token") ||
          error.message.includes("Unauthorized error, `Unauthorized`")
        ) {
          if (operation.operationName != "refreshToken") {
            let forward$;

            if (!isRefreshing) {
              isRefreshing = true;
              forward$ = fromPromise(
                refreshToken()
                  .then(({ accessToken, refreshToken }) => {
                    // Store the new tokens for your auth link
                    resolvePendingRequests();
                    return accessToken;
                  })
                  .catch((error) => {
                    pendingRequests = [];
                    // Handle token refresh errors e.g clear stored tokens, redirect to login, ...
                    return;
                  })
                  .finally(() => {
                    isRefreshing = false;
                  })
              ).filter((value) => Boolean(value));
            } else {
              // Will only emit once the Promise is resolved
              forward$ = fromPromise(
                new Promise((resolve) => {
                  pendingRequests.push(() => resolve());
                })
              );
            }

            return forward$.flatMap(() => forward(operation));
          }
        }
        // when AuthenticationError thrown in resolver
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      // if you would also like to retry automatically on
      // network errors, we recommend that you use
      // apollo-link-retry
    }
  }
);

const cache = new InMemoryCache();
const apolloClient = new ApolloClient({
  cache,
  // link: authLink.concat(httpLink),
  link: from([errorLink, authLink.concat(httpLink)]),
});

const ADD_TODO = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      refreshToken
      accessToken
    }
  }
`;

const {
  mutate,
  loading: refreshing,
  onDone,
} = provideApolloClient(apolloClient)(() => useMutation(ADD_TODO));

const sleep = (t) => new Promise((resolve) => setTimeout(resolve, t));

async function refreshToken() {
  let refreshToken = JSON.parse(
    localStorage.getItem("_tokenInfo")
  ).refreshToken;

  if (!refreshToken) logout();

  if (refreshing.value) {
    await sleep(2500);
    return;
  }

  return new Promise((resolve, reject) => {
    mutate({ refreshToken });
    onDone(({ data }) => {
      let { accessToken, refreshToken } = data.refreshToken;
      const tokenInfo = JSON.parse(localStorage.getItem("_tokenInfo"));
      tokenInfo.token = accessToken;
      tokenInfo.refreshToken = refreshToken;
      localStorage.setItem("_tokenInfo", JSON.stringify(tokenInfo));
      resolve({ accessToken });
    });
    // refreshTokenError(reject);
  });
}

function logout(params) {
  // localStorage.clear();
  router.replace("/login");
}

export default apolloClient;
