import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './version';

import Antd from "ant-design-vue";
import { notification } from "ant-design-vue";
import { Modal } from "ant-design-vue";

// 样式导入
import "ant-design-vue/dist/antd.css";
import "./assets/style/style.scss";
import "@/assets/style/space.css";

import $filters from "@/utils/filters";
import $msg from "@/utils/message";

import gql from "graphql-tag";

import { DefaultApolloClient } from "@vue/apollo-composable";

import apolloClient from "./utils/apollo";

const app = createApp(App);

app.provide("$", { $filters, gql, $msg });
app.provide(DefaultApolloClient, apolloClient);

// 注册全局过滤方法
app.config.globalProperties.$filters = $filters;

app.use(Antd).use(store).use(router).mount("#app");
