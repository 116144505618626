<template>
  <router-view />
</template>
<script>
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
export default {
  components: {},
  setup() {
  }
};
</script>

<style></style>
