import Layout from "@/views/layout/layout.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Layout,
    children: [
      {
        path: "/",
        name: "Overview",
        meta: { title: "overview" },
        component: () => import("@/views/Overview.vue"),
      },
      {
        path: "projects",
        name: "Projects",
        meta: { title: "Projects" },
        component: () => import("@/views/Projects.vue"),
      },
      {
        path: "projects/chart",
        name: "project-chart",
        meta: { title: "Project chart" },
        component: () => import("@/views/project/Chart.vue"),
      },
      {
        path: "projects/issues",
        name: "project-issues",
        meta: { title: "Project issues" },
        component: () => import("@/views/project/Issues.vue"),
      },
      {
        path: "projects/milestone",
        name: "project-milestone",
        meta: { title: "Project Milestone" },
        component: () => import("@/views/project/Milestone.vue"),
      },
      {
        path: "projects/burnupchart",
        name: "project-burnupchart",
        meta: { title: "Project Milestone" },
        component: () => import("@/views/project/BurnupChart.vue"),
      },
      {
        path: "/user",
        name: "User",
        meta: { title: "User" },
        component: () => import("@/views/User.vue"),
      },

      {
        path: "user/chart",
        name: "User-chart",
        meta: { title: "User chart" },
        component: () => import("@/views/User.vue"),
      },
      {
        path: "user/issues",
        name: "User-issues",
        meta: { title: "User issues" },
        component: () => import("@/views/user/Issues.vue"),
      },

      //  管理员板块
      {
        path: "/admin",
        name: "Admin",
        meta: { title: "Admin" },
        component: () => import("@/views/admin/Admin.vue"),
        children: [],
      },
      {
        path: "/admin/projects",
        name: "admin-Projects",
        meta: { title: "Projects" },
        component: () => import("@/views/Projects.vue"),
      },
      {
        path: "/admin/projects/chart",
        name: "admin-project-chart",
        meta: { title: "Project chart" },
        component: () => import("@/views/project/Chart.vue"),
      },
      {
        path: "/admin/projects/issues",
        name: "admin-project-issues",
        meta: { title: "Project issues" },
        component: () => import("@/views/project/Issues.vue"),
      },
      {
        path: "/admin/projects/issuechart",
        name: "admin-project-issue-chart",
        meta: { title: "Project issue chart" },
        component: () => import("@/views/project/IssuesChart.vue"),
      },
      {
        path: "/admin/projects/milestone",
        name: "admin-project-milestone",
        meta: { title: "Project Milestone" },
        component: () => import("@/views/project/Milestone.vue"),
      },
      {
        path: "/admin/user",
        name: "Admin user",
        meta: { title: "user" },
        component: () => import("@/views/admin/User.vue"),
      },
      // {
      //   path: "/admin/user/users",
      //   name: "Admin-User-users",
      //   meta: { title: "Users" },
      //   component: () => import("@/views/user/Users.vue"),
      // },
      {
        path: "/admin/user/chart",
        name: "Admin-User-chart",
        meta: { title: "User chart" },
        component: () => import("@/views/admin/User.vue"),
      },
      {
        path: "/admin/user/issues",
        name: "Admin-User-issues",
        meta: { title: "User issue" },
        component: () => import("@/views/user/Issues.vue"),
      },
      {
        path: "/admin/system/users",
        name: "admin-system-administrators",
        component: () => import("@/views/admin/Administrators.vue"),
        meta: {
          title: "system-administrators",
        },
      },
      {
        path: "/admin/onDutyTask",
        name: "admin-onDutyTask",
        component: () => import("@/views/on_duty_task/OnDutyTask.vue"),
        meta: {
          title: "system-on-duty-task",
        },
      },
      {
        path: "/onDutyEvent",
        name: "admin-onDutyEvent",
        component: () => import("@/views/on_duty_task/OnDutyEvent.vue"),
        meta: {
          title: "system-on-duty-event",
        },
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
    Meta: { title: "NotFound" },
  },
];
export default routes;
