<template>
  <a-menu mode="inline" :inline-collapsed="collapsed" v-model:openKeys="openKeys" v-model:selectedKeys="selectedKeys">
    <a-menu-item v-for="(item, index) of menuList" :key="index" @click="navigate(item.link)">
      <component :is="item.icon" />
      <span>{{ item.name }}</span>
    </a-menu-item>
    <a-sub-menu key="project">
      <template #title>
        <span @click="
          navigate(projectSecond[0].link);
        selectedKeys = ['project' + projectSecond[0].name];">
          <ProjectOutlined />
          <span>Project</span>
        </span>
      </template>
      <a-menu-item :key="'project' + item.name" v-for="item in projectSecond" @click="navigate(item.link)">
        <component :is="item.icon" />
        <span>{{ item.name }}</span>
      </a-menu-item>
    </a-sub-menu>
    <a-sub-menu key="user">
      <template #title>
        <span @click="
          navigate(userSecond[0].link);
        selectedKeys = ['user' + userSecond[0].name];">
          <UserOutlined />
          <span>User</span>
        </span>
      </template>
      <a-menu-item :key="'user' + item.name" v-for="item in userSecond" @click="navigate(item.link)">
        <component :is="item.icon" />
        <span>{{ item.name }}</span>
      </a-menu-item>
    </a-sub-menu>
    <a-sub-menu key="system" v-if="isAdmin">
      <template #title>
        <span @click="navigate(systemSecond[0].link)">
          <SafetyCertificateOutlined />
          <span>System</span>
        </span>
      </template>
      <a-menu-item :key="'system' + item.name" v-for="item in systemSecond" @click="navigate(item.link)">
        <component :is="item.icon" />
        <span>{{ item.name }}</span>
      </a-menu-item>
    </a-sub-menu>
    <a-menu-item key="OnDutyTask" @click="navigate('/admin/onDutyTask')" v-if="isAdmin">
      <CheckSquareOutlined />
      <span>On Duty Task</span>
    </a-menu-item>
    <a-menu-item key="OnDutyEvent" @click="navigate('/onDutyEvent')">
      <CheckSquareOutlined />
      <span>On Duty Event</span>
    </a-menu-item>
  </a-menu>
</template>
<script>
import { reactive, toRefs, watch, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  TeamOutlined,
  PayCircleOutlined,
  WalletOutlined,
  HomeOutlined,
  UserOutlined,
  FolderOpenOutlined,
  ProjectOutlined,
  IssuesCloseOutlined,
  SafetyCertificateOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons-vue";
let menuList = [
  {
    name: "Overview",
    link: "/",
    icon: "HomeOutlined",
  },
];
let projectSecond = [
  {
    name: "Your Projects",
    link: "/projects",
  },
  {
    name: "Project Chart",
    link: "/projects/chart",
  },
  {
    name: "Issues",
    link: "/projects/issues",
  },
  {
    name: "Milestone",
    link: "/projects/milestone",
  },
  {
    name: "Burnup Chart",
    link: "/projects/burnupchart",
  },
  // {
  //   name: "Issue Chart",
  //   link: "/projects/issuechart",
  // },
];
let userSecond = [
  {
    name: "Chart",
    link: "/user/chart",
  },
  {
    name: "Issues",
    link: "/user/issues",
  },
];

let systemSecond = [];
let OnDutyTaskSecond = [];

export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const v = reactive({
      collapsed: false,
      selectedKeys: [0],
      openKeys: [],
      preOpenKeys: ["sub1"],
    });
    watch(
      () => v.openKeys,
      (val, oldVal) => {
        v.preOpenKeys = oldVal;
      }
    );

    const isAdmin = computed(() =>
      store.state.roles.some((item) => item == "MANAGER" || item == "ADMIN")
    );

    if (isAdmin.value) {
      projectSecond = [
        {
          name: "All Projects",
          link: "/admin/projects",
        },
        {
          name: "Project Chart",
          link: "/admin/projects/chart",
        },
        {
          name: "Issues",
          link: "/admin/projects/issues",
        },
        {
          name: "Milestone",
          link: "/admin/projects/milestone",
        },
      ];
      userSecond = [
        {
          name: "Chart",
          link: "/admin/user/chart",
        },
        {
          name: "Issues",
          link: "/admin/user/issues",
        },
      ];
      systemSecond = [
        {
          name: "Users",
          link: "/admin/system/users",
        },
      ];
    }

    // 根据当前页面路径选中对应Menu
    Object.keys(projectSecond).map((key) => {
      if (projectSecond[key].link == router.currentRoute.value.path) {
        v.selectedKeys = ["project" + projectSecond[key].name];
        v.openKeys = ["project"];
        return;
      }

      if (
        router.currentRoute.value.path.indexOf(projectSecond[key].link) >= 0
      ) {
        v.selectedKeys = [projectSecond[key].name];
        v.openKeys = ["project"];
        return;
      }
    });

    Object.keys(userSecond).map((key) => {
      if (userSecond[key].link == router.currentRoute.value.path) {
        v.selectedKeys = ["user" + userSecond[key].name];
        v.openKeys = ["user"];
        return;
      }

      if (router.currentRoute.value.path.indexOf(userSecond[key].link) >= 0) {
        v.selectedKeys = [userSecond[key].name];
        v.openKeys = ["user"];
        return;
      }
    });

    Object.keys(systemSecond).map((key) => {
      if (systemSecond[key].link == router.currentRoute.value.path) {
        v.selectedKeys = ["system" + systemSecond[key].name];
        v.openKeys = ["system"];
        return;
      }

      if (router.currentRoute.value.path.indexOf(systemSecond[key].link) >= 0) {
        v.selectedKeys = [systemSecond[key].name];
        v.openKeys = ["system"];
        return;
      }
    });

    if (router.currentRoute.value.path.indexOf('/onDutyTask') >= 0) {
      v.selectedKeys = ['OnDutyTask'];
      v.openKeys = ["OnDutyTask"];
    }

    if (router.currentRoute.value.path.indexOf('/onDutyEvent') >= 0) {
      v.selectedKeys = ['OnDutyEvent'];
      v.openKeys = ["OnDutyEvent"];
    }


    const toggleCollapsed = () => {
      v.collapsed = !v.collapsed;
      v.openKeys = v.collapsed ? [] : v.preOpenKeys;
    };

    // 页面切换
    const navigate = (e) => {
      router.replace(e);
    };
    const titleClick = (e, key) => {
      //  v.selectedKeys = [+key];
      // router.replace(`/${key}`);
    };

    return {
      ...toRefs(v),
      toggleCollapsed,
      menuList,
      projectSecond,
      userSecond,
      navigate,
      titleClick,
      WalletOutlined,
      systemSecond,
      OnDutyTaskSecond,
      isAdmin,
    };
  },

  components: {
    PayCircleOutlined,
    TeamOutlined,
    WalletOutlined,
    HomeOutlined,
    UserOutlined,
    FolderOpenOutlined,
    ProjectOutlined,
    IssuesCloseOutlined,
    SafetyCertificateOutlined,
    CheckSquareOutlined,
  },
};
</script>
